.boton {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: rgb(212, 163, 17);
	background: linear-gradient(302deg, rgba(212, 163, 17, 1) 7%, rgba(212, 163, 17, 1) 13%, rgba(232, 209, 141, 1) 20%, rgba(246, 204, 71, 1) 35%, rgba(237, 220, 164, 1) 50%, rgba(212, 163, 17, 1) 67%, rgba(232, 209, 141, 1) 88%, rgba(212, 163, 17, 1) 94%);
	;
	color: #fff;
	font-weight: 500;
	border: none;
	cursor: pointer;
	text-transform: capitalize;
	transition: .5s ease all;
	position: relative;
	overflow: hidden;
}

.boton span {
	position: relative;
	z-index: 2;
	transition: .3s ease all;
}

/* primer estilo */

.boton.one::after {
	content: "";
	width: 100%;
	height: 300px;
	background: rgb(212, 163, 17);
	background: linear-gradient(302deg, rgba(212, 163, 17, 1) 7%, rgba(212, 163, 17, 1) 13%, rgba(232, 209, 141, 1) 20%, rgba(246, 204, 71, 1) 35%, rgba(237, 220, 164, 1) 50%, rgba(212, 163, 17, 1) 67%, rgba(232, 209, 141, 1) 88%, rgba(212, 163, 17, 1) 94%);
	position: absolute;
	z-index: 1;
	top: -300px;
	left: 0;
	transition: .6s ease-in-out all;
	border-radius: 0px 0px 300px 300px;
}

.boton.one:hover::after {
	top: 0;
}

/* segundo estilo */

.boton.two::after {
	content: "";
	width: 100%;
	height: 100%;
	background: rgb(212,163,17);
	background: linear-gradient(302deg, rgba(212,163,17,1) 7%, rgba(212,163,17,1) 13%, rgba(232,209,141,1) 20%, rgba(246,204,71,1) 35%, rgba(237,220,164,1) 50%, rgba(212,163,17,1) 67%, rgba(232,209,141,1) 88%, rgba(212,163,17,1) 94%); 
	position: absolute;
	z-index: 1;
	top: -80px;
	left: 0;
	transition: .6s ease-in-out all;
}

.boton.two:hover::after {
	top: 0;
}

/* tercer estilo */

.boton.three::after {
	content: "";
	width: 1px;
	height: 1px;
	background: none;
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 50%;
	transition: .9s ease-in-out all;
	border-radius: 100px;
	transform-origin: center;
}

.boton.three:hover::after {
	transform: scale(400);
	background: rgb(212,163,17);
	background: linear-gradient(302deg, rgba(212,163,17,1) 7%, rgba(212,163,17,1) 13%, rgba(232,209,141,1) 20%, rgba(246,204,71,1) 35%, rgba(237,220,164,1) 50%, rgba(212,163,17,1) 67%, rgba(232,209,141,1) 88%, rgba(212,163,17,1) 94%); 
}

/*boton del formulario */

.formContent__btn {
	display: inline-flex !important;
	align-items: center !important;
	justify-content: center !important;
	background: var(--btn) !important;
	color: #fff !important;
	font-weight: 500 !important;
	border: none !important;
	cursor: pointer !important;
	text-transform: capitalize !important;
	transition: .5s ease all !important;
	position: relative !important;
	overflow: hidden !important;
	width: auto !important;
	padding: 16px 24px !important;
	display: flex !important;
	justify-content: flex-start !important;
}